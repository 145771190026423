/** @format */

import axios from "axios";
import React, { useEffect } from "react";
import { useHistory, Route, Link } from "react-router-dom";
import "../dashboard.css";

import WelcomeDashboard from "./WelcomeDashboard";
import CagesDashboard from "./CagesDashboard";
import CageEdit from "./CageEdit";
import CustomersDashboard from "./CustomersDashboard";
import GalleryDashboard from "./GalleryDashboard";

const Dashboard = () => {
  let historyRedirect = useHistory();

  useEffect(() => {
    if (localStorage.getItem("ppsr_token")) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/cages/auth`,
        headers: {
          authorization: JSON.parse(localStorage.getItem("ppsr_token")),
        },
        response: "json",
      })
        .then(result => {
          // console.log("AUTH CHECK RESULT", result);
          historyRedirect.push("/dashboard/welcome");
        })
        .catch(err => {
          // console.log("AUTH ERR", err);
          localStorage.clear();
          historyRedirect.push("/login");
        });
    } else {
      localStorage.clear();
      historyRedirect.push("/login");
    }
  }, [historyRedirect]);

  return (
    <div>
      <div className='dashboard-title-container'>
        <div>PPSR Dashboard</div>
        <div>{localStorage.getItem("ppsr_user")}</div>
      </div>
      <div className='dashboard-body-container'>
        <div className='dbc-navigation'>
          <div className='dbc-nav-links-container'>
            <div className='dbc-nav-link'>
              <Link to='/dashboard/customers'>Customers</Link>
            </div>
            <div className='dbc-nav-link'>
              <Link to='/dashboard/cages'>Cages</Link>
            </div>
            <div className='dbc-nav-link'>
              <Link to='/dashboard/gallery'>Gallery</Link>
            </div>
            <div className='dbc-nav-link'>
              <Link to='/' onClick={event => localStorage.clear()}>
                Logout
              </Link>
            </div>
          </div>
        </div>
        <div className='dbc-content'>
          <Route
            path='/dashboard/welcome'
            render={props => <WelcomeDashboard />}
          />
          <Route
            path='/dashboard/customers'
            render={props => <CustomersDashboard />}
          />
          <Route
            exact={true}
            path='/dashboard/cages'
            render={props => <CagesDashboard />}
          />
          <Route path={`/dashboard/cages/:id`} render={props => <CageEdit />} />
          <Route
            path={`/dashboard/gallery`}
            render={props => <GalleryDashboard />}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
