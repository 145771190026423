/** @format */

import axios from "axios";
import React, { useState, useEffect } from "react";

const AddCagePart = ({
  showAddSection,
  setShowAddSection,
  removedCagePart,
  setRemovedCagePart,
  definedParts,
  setDefinedParts,
}) => {
  const [price, setPrice] = useState({ price: "" });
  const [cageType, setCageType] = useState({ type: "" });
  const [cagePart, setCagePart] = useState({ part: "" });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setPrice({ price: "" });
      setCageType({ type: "" });
      setCagePart({ part: "" });
    }

    return () => {
      isMounted = false;
    };
  }, [showAddSection]);

  const addNewCage = event => {
    event.preventDefault();
    if (!definedParts.includes(cageType.type + cagePart.part)) {
      if (
        cagePart.part.length > 0 &&
        cageType.type.length > 0 &&
        price.price.length > 0
      ) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/cages/post`,
          headers: {
            authorization: JSON.parse(localStorage.getItem("ppsr_token")),
          },
          data: {
            cageType: cageType.type,
            cagePart: cagePart.part,
            price: parseInt(price.price),
          },
          response: "json",
        })
          .then(result => {
            setRemovedCagePart(removedCagePart + result.data);
            setShowAddSection(!showAddSection);
            setDefinedParts(...definedParts, cageType.type + cagePart.part);
          })
          .catch(err => {
            console.log("post error", err);
          });
      } else {
        alert("Something went wrong!\nCheck inputs.");
      }
    } else {
      alert(`${cageType.type} ${cagePart.part} already exists!`);
    }
  };

  return (
    <div>
      <div>
        <select
          name='Cage Type'
          required
          onChange={event => setCageType({ type: event.target.value })}
        >
          <option value=''>Choose Cage Type</option>
          <option value='Dome'>Dome</option>
          <option value='Gable'>Gable</option>
        </select>

        <select
          name='Cage Part'
          required
          onChange={event => setCagePart({ part: event.target.value })}
        >
          <option value=''>Choose Cage Part</option>
          <option value='Door'>Door</option>
          <option value='Bottom'>Bottom</option>
          <option value='Side'>Side</option>
          <option value='Riser'>Riser</option>
          <option value='Roof'>Roof</option>
          <option value='Low Riser'>Low Riser</option>
          <option value='High Riser'>High Riser</option>
          <option value='Low Roof'>Low Roof</option>
          <option value='High Roof'>High Roof</option>
        </select>

        <input
          type='number'
          name='standard price'
          placeholder='standard screen price'
          value={price.price}
          autoComplete='off'
          required
          onChange={event => setPrice({ price: event.target.value })}
        />
      </div>
      <div>
        <button onClick={event => addNewCage(event)}>Submit</button>
      </div>
    </div>
  );
};

export default AddCagePart;
