/** @format */

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import "../cagePart.css";

import AddAltScreen from "./AddAltScreen";

const CageEdit = () => {
  let historyRedirect = useHistory();
  let { id } = useParams();
  const [cagePart, setCagePart] = useState(null);
  let [altScreenCount, setAltScreenCount] = useState(null);
  const [editEnabled, setEditEnabled] = useState({ name: "" });
  const [inputValues, setInputValues] = useState({ inputValues: "" });
  const [showAddAltScreen, setShowAddAltScreen] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (localStorage.getItem("ppsr_token")) {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/cages/${id}`,
          headers: {
            authorization: JSON.parse(localStorage.getItem("ppsr_token")),
          },
          response: "json",
        })
          .then(result => {
            setCagePart(result.data);
          })
          .catch(err => {
            localStorage.clear();
            historyRedirect.push("/login");
          });
      } else {
        localStorage.clear();
        historyRedirect.push("/login");
      }
    }

    return () => {
      isMounted = false;
    };
  }, [id, historyRedirect, altScreenCount]);

  const updateCagePartPrice = part => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/cages/${part.id}/cage/update`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      data: {
        price: `${inputValues[editEnabled.name]}`,
      },
      response: "json",
    })
      .then(result => {
        setAltScreenCount((altScreenCount += result.data));
        setEditEnabled({ name: "" });
      })
      .catch(err => {
        console.log(err);
        alert(
          `Oooops!\nStandard screen price for ${editEnabled.name} Panel did not update!`
        );
        setEditEnabled({ name: "" });
      });
  };

  const updateCagePartImage = (part, image) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/cages/${part.id}/cage/update`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      data: {
        imageURL: image,
      },
      response: "json",
    })
      .then(result => {
        // console.log("image update", result);
        setAltScreenCount((altScreenCount += 1));
      })
      .catch(err => {
        console.log(err);
        alert("Ooooops!\nImage did not update!");
      });
  };

  const uploadCageImage = (event, file, part) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET_CAGE);

    const config = {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    };

    let isMounted = true;
    if (isMounted) {
      axios
        .post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
          formData,
          config
        )
        .then(result => {
          // console.log("cage image uploaded", result);
          setShowUpdateImage(!showUpdateImage);
          updateCagePartImage(part, result.data.secure_url);
        })
        .catch(err => {
          console.log(err);
          alert("Something went wrong.\nFailed to upload image.");
        });
    }
  };

  const updateCagePartAlternativePrice = altScreen => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/cages/screen/${altScreen.id}/update`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      data: {
        price: `${inputValues[editEnabled.name]}`,
      },
      response: "json",
    })
      .then(result => {
        setAltScreenCount((altScreenCount += result.data));
        setEditEnabled({ name: "" });
      })
      .catch(err => {
        alert(
          `Oooops!\n${editEnabled.name} price for ${cagePart.cageType} ${cagePart.cagePart} Panel did not update!`
        );
        setEditEnabled({ name: "" });
      });
  };

  const deleteCagePartAlternativeScreen = altScreen => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/cages/screen/${altScreen.id}/delete`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      response: "json",
    })
      .then(result => {
        setAltScreenCount((altScreenCount += result.data));
      })
      .catch(err => {
        alert("Something went wrong, Alt Screen was not deleted!");
      });
  };

  const deleteAltScreenConfirmBox = (event, screen) => {
    event.preventDefault();
    const deleteCheck = window.confirm(
      `Are you sure you want to delete ${screen.altScreenName} from ${cagePart.cageType} ${cagePart.cagePart}?`
    );

    if (deleteCheck) {
      deleteCagePartAlternativeScreen(screen);
    }
  };

  const editInputSwitch = (event, cagePartName, price, part, updateNumber) => {
    event.preventDefault();
    //update standard screen
    if (cagePartName === editEnabled.name && updateNumber === 1) {
      updateCagePartPrice(part);
    }

    //update alternative screen
    if (cagePartName === editEnabled.name && updateNumber === 2) {
      updateCagePartAlternativePrice(part);
    }

    setEditEnabled({ name: cagePartName });
    setInputValues({ [cagePartName]: price });
  };

  const showAddSwitch = event => {
    event.preventDefault();
    setShowAddAltScreen(!showAddAltScreen);
  };

  const showUpdateImageSwitch = event => {
    event.preventDefault();
    setShowUpdateImage(!showUpdateImage);
  };

  if (cagePart === null) {
    return <>Loading</>;
  }

  return (
    <div className='cage-part-container'>
      {
        //////////////////////////////////////
        // Image and Standard Screen Section
        //////////////////////////////////////
      }
      <div className='cage-part-title'>
        {cagePart.cageType} Cage: {cagePart.cagePart} Panel Edit Screen
      </div>
      <br />
      <div className='cage-part-sections'>
        <div className='cage-part-image'>
          <img
            src={cagePart.imageURL}
            alt={`${cagePart.cageType} ${cagePart.cagePart} Panel`}
          />
          {!showUpdateImage ? (
            <button onClick={event => showUpdateImageSwitch(event)}>
              Change Image
            </button>
          ) : (
            <></>
          )}

          {showUpdateImage ? (
            <input
              type='file'
              multiple={false}
              onChange={event =>
                uploadCageImage(event, event.target.files, cagePart)
              }
            />
          ) : (
            <></>
          )}
          <div className='cage-part-regular-screen'>
            <hr />
            <div>Standard 18x14 Screen</div>
            <input
              type='number'
              name={cagePart.cageType + " " + cagePart.cagePart}
              placeholder={cagePart.price}
              value={
                editEnabled.name === cagePart.cageType + " " + cagePart.cagePart
                  ? inputValues[cagePart.cageType + " " + cagePart.cagePart]
                  : cagePart.price
              }
              disabled={
                editEnabled.name === cagePart.cageType + " " + cagePart.cagePart
                  ? false
                  : true
              }
              onChange={event =>
                setInputValues({
                  [cagePart.cageType + " " + cagePart.cagePart]:
                    event.target.value,
                })
              }
            />
            <div>
              <button
                onClick={event => {
                  editInputSwitch(
                    event,
                    cagePart.cageType + " " + cagePart.cagePart,
                    cagePart.price,
                    cagePart,
                    1
                  );
                }}
              >
                {editEnabled.name ===
                cagePart.cageType + " " + cagePart.cagePart
                  ? "Submit"
                  : "Edit"}
              </button>
            </div>
          </div>
        </div>
        <div className='cage-part-altScreens-container'>
          <div className='cage-part-altScreen-title'>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Alternative Screens
              <button onClick={event => showAddSwitch(event)}>
                {showAddAltScreen ? "Cancel" : "Add"}
              </button>
            </div>
            <div>
              {showAddAltScreen ? (
                <AddAltScreen
                  altScreenCount={altScreenCount}
                  setAltScreenCount={setAltScreenCount}
                  showAddAltScreen={showAddAltScreen}
                  setShowAddAltScreen={setShowAddAltScreen}
                />
              ) : (
                <></>
              )}
            </div>
            <hr />
          </div>

          {
            ///////////////////////
            // Alt Screens Section
            ///////////////////////
          }
          {cagePart.altScreenOptions.map(screen => {
            return (
              <div className='cage-part-altScreen-item' key={screen.id}>
                <div style={{ fontWeight: "bold" }}>{screen.altScreenName}</div>
                <input
                  type='number'
                  name={screen.altScreenName}
                  placeholder={screen.price}
                  value={
                    editEnabled.name === screen.altScreenName
                      ? inputValues[screen.altScreenName]
                      : screen.price
                  }
                  disabled={
                    editEnabled.name === screen.altScreenName ? false : true
                  }
                  onChange={event =>
                    setInputValues({
                      [screen.altScreenName]: event.target.value,
                    })
                  }
                />
                <div>
                  <button
                    onClick={event =>
                      editInputSwitch(
                        event,
                        screen.altScreenName,
                        screen.price,
                        screen,
                        2
                      )
                    }
                  >
                    {editEnabled.name === screen.altScreenName
                      ? "Submit"
                      : "Edit"}
                  </button>
                  <button
                    onClick={event => deleteAltScreenConfirmBox(event, screen)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CageEdit;
