/** @format */

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const CustomersDashboard = () => {
  let historyRedirect = useHistory();
  const [customers, setCustomers] = useState(null);
  let [removedUser, setRemovedUser] = useState(null);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/users/all`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      response: "json",
    })
      .then(result => {
        setCustomers(result.data);
      })
      .catch(err => {
        console.log("Error", err);
        localStorage.clear();
        historyRedirect.push("/login");
      });
  }, [historyRedirect, removedUser]);

  const deleteUser = customer => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/users/delete`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      data: {
        email: `${customer.email}`,
      },
      response: "json",
    })
      .then(result => {
        setRemovedUser((removedUser += result.data.deleted));
      })
      .catch(err => {
        alert("Something went wrong, Customer was not deleted!");
      });
  };

  const deleteConfirmBox = (event, customer) => {
    event.preventDefault();
    const deleteCheck = window.prompt(
      `Are you sure you want to delete ${customer.firstName} (${customer.email})?\nType DELETE and hit OK to confirm!`
    );

    if (deleteCheck === "DELETE") {
      deleteUser(customer);
    }
  };

  if (customers === null) {
    return <>Loading...</>;
  }

  return (
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th colSpan='5'>Customers Data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>Last Name</td>
              <td>Email</td>
              <td>Role</td>
              <td>Confirmed</td>
            </tr>
            {customers.map(customer => {
              return (
                <tr key={customer.id}>
                  <td>{customer.firstName}</td>
                  <td>{customer.lastName}</td>
                  <td>{customer.email}</td>
                  <td>{customer.userRole}</td>
                  {customer.activeUser ? (
                    <td style={{ backgroundColor: "lightgreen" }}>
                      {customer.activeUser.toString()}
                    </td>
                  ) : (
                    <td style={{ backgroundColor: "salmon" }}>
                      {customer.activeUser.toString()}
                    </td>
                  )}
                  <td>
                    <button
                      onClick={event => {
                        deleteConfirmBox(event, customer);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomersDashboard;
