/** @format */

import React from "react";

const WelcomeDashboard = () => {
  return (
    <div>
      <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
        Welcome to PPSR Admin Dashboard.
      </div>
    </div>
  );
};

export default WelcomeDashboard;
