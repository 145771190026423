/** @format */

import axios from "axios";
import React, { useState, useEffect } from "react";
import "../gallery.css";

const GalleryDashboard = () => {
  const [files, setFiles] = useState([]);
  let [imageAdded, setImageAdded] = useState("");
  const [showAddImage, setShowAddImage] = useState(false);

  const displayGallery = () => {
    // console.log("file count", files.length);
    if (files.length < 1) {
      //   console.log(files.length);
      return <></>;
    } else {
      return files.map(image => {
        return (
          <div
            key={image.id}
            style={{
              width: "200px",
              border: "1px solid #222",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "10px 10px",
            }}
          >
            {/* <img
              style={{
                width: "150px",
                height: "150px",
                margin: "15px 15px 0px, 15px",
              }}
              src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUD_NAME}/image/upload/v${image.version}/${image.public_id}.${image.format}`}
              alt='gallery'
            /> */}
            <img
              style={{
                width: "150px",
                height: "150px",
                margin: "15px 15px 0px, 15px",
              }}
              src={image.imageURL}
              alt='gallery pool and patio screen repair'
            />
            <button onClick={event => deleteImageConfirm(event, image.id)}>
              Delete
            </button>
          </div>
        );
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      // axios({
      //   method: "get",
      //   url: `https://res.cloudinary.com/${process.env.REACT_APP_CLOUD_NAME}/image/list/ppsr_gallery.json`,
      //   response: "json",
      // })
      //   .then(result => {
      //     //   console.log("image search results", result.data);
      //     setFiles(result.data.resources);
      //   })
      //   .catch(err => {
      //     console.log("image req error", err);
      //   });
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/gallery/all`,
        headers: {},
        response: "json",
      })
        .then(result => {
          // console.log("gallery", result.data);
          setFiles(result.data);
        })
        .catch(err => {
          console.log("gallery get all failed", err);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [imageAdded]);

  const deleteImageConfirm = (event, imageId) => {
    event.preventDefault();
    const deleteImageCheck = window.confirm(
      `Are you sure you want to delete image from gallery?`
    );

    if (deleteImageCheck) {
      deleteGalleryImage(imageId);
    }
  };

  const deleteGalleryImage = imageId => {
    let isMounted = true;

    if (isMounted) {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/gallery/delete`,
        headers: {
          authorization: JSON.parse(localStorage.getItem("ppsr_token")),
        },
        data: {
          imageId: imageId,
        },
        response: "json",
      })
        .then(result => {
          // console.log("image delete", result);
          setImageAdded(imageAdded + 1);
        })
        .catch(err => {
          console.log("image delete fail", err);
        });
    }
  };

  const updateImageGallery = imageUrl => {
    let isMounted = true;

    if (isMounted) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/gallery/post`,
        headers: {
          authorization: JSON.parse(localStorage.getItem("ppsr_token")),
        },
        data: {
          imageURL: imageUrl,
        },
        response: "json",
      })
        .then(result => {
          // console.log("gallery post", result);
          setImageAdded(imageAdded + result.data);
        })
        .catch(err => {
          console.log("gallery post fail", err);
        });
    }

    return () => {
      isMounted = false;
    };
  };

  const uploadImage = (event, file) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append(
      "upload_preset",
      process.env.REACT_APP_UPLOAD_PRESET_GALLERY
    );

    const config = {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    };

    let isMounted = true;

    if (isMounted) {
      axios
        .post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
          formData,
          config
        )
        .then(result => {
          // console.log(result);
          // setImageAdded(result.data.secure_url);
          updateImageGallery(result.data.secure_url);
          setShowAddImage(!showAddImage);
          // if (result.status === 200) {
          //   alert(
          //     "Success!\nFile is uploaded, it may take a few moments for the CLOUD to process."
          //   );
          // }
        })
        .catch(err => {
          console.log("upload error", err);
        });
    }

    return () => {
      isMounted = false;
    };
  };

  const showAddContainer = event => {
    setShowAddImage(!showAddImage);
  };

  return (
    <div>
      <div style={{ textAlign: "center", fontSize: "20px" }}>
        <div>Image Gallery</div>
        <button onClick={event => showAddContainer(event)}>
          {showAddImage ? "Cancel" : "Add"}
        </button>
      </div>
      {showAddImage ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type='file'
            multiple={false}
            onChange={event => uploadImage(event, event.target.files)}
          />
        </div>
      ) : (
        <></>
      )}

      <hr />
      <div>Count: {files.length}</div>
      <div className='gallery-container' style={{ marginTop: "50px" }}>
        {displayGallery()}
      </div>
    </div>
  );
};

export default GalleryDashboard;
