/** @format */

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../tables.css";

import AddCagePart from "./AddCagePart";

const CagesDashboard = () => {
  let historyRedirect = useHistory();
  const [cages, setCages] = useState(null);
  const [showAddSection, setShowAddSection] = useState(false);
  let [removedCagePart, setRemovedCagePart] = useState(null);
  const [definedParts, setDefinedParts] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/cages/all`,
        headers: {
          authorization: JSON.parse(localStorage.getItem("ppsr_token")),
        },
        response: "json",
      })
        .then(result => {
          setCages(result.data);
          fillDefinedParts(result.data);
          // console.log("parts array", definedParts);
        })
        .catch(err => {
          console.log("Error", err);
          localStorage.clear();
          historyRedirect.push("/login");
        });
    }
    return () => {
      isMounted = false;
    };
  }, [historyRedirect, removedCagePart]);

  const fillDefinedParts = cages => {
    let newDefinedParts = [];
    for (let item in cages) {
      if (
        !newDefinedParts.includes(cages[item].cageType + cages[item].cagePart)
      ) {
        newDefinedParts.push(cages[item].cageType + cages[item].cagePart);
      }
    }
    setDefinedParts(newDefinedParts);
  };

  const editCagePart = (event, cage) => {
    event.preventDefault();
    historyRedirect.push(`/dashboard/cages/${cage.id}`);
  };

  const deleteCagePart = cage => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/cages/delete`,
      headers: {
        authorization: JSON.parse(localStorage.getItem("ppsr_token")),
      },
      data: {
        cageId: cage.id,
      },
      response: "json",
    })
      .then(result => {
        setRemovedCagePart((removedCagePart += result.data.deleted));
      })
      .catch(err => {
        console.log(err);
        alert("Something went wrong, Cage Part was not deleted!");
      });
  };

  const deleteCagePartConfirmBox = (event, cage) => {
    event.preventDefault();
    const deleteCheck = window.prompt(
      `Are you sure you want to delete ${cage.cageType} ${cage.cagePart} and all its Alternative Screen Options?\nType DELETE and hit OK to confirm.`
    );

    if (deleteCheck) {
      deleteCagePart(cage);
    }
  };

  const showAddNewCage = event => {
    event.preventDefault();
    setShowAddSection(!showAddSection);
  };

  if (cages === null) {
    return <>Loading...</>;
  }

  return (
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th colSpan='4'>Cages Data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Type</td>
              <td>Regular Price</td>
              <td>Alternative Screens</td>
              <td>Alt. Price</td>
              <td>
                <button
                  onClick={event => {
                    showAddNewCage(event);
                  }}
                >
                  {showAddSection ? "Cancel" : "Add"}
                </button>
              </td>
            </tr>
            <tr>
              <th colSpan='5'>
                {showAddSection ? (
                  <AddCagePart
                    showAddSection={showAddSection}
                    setShowAddSection={setShowAddSection}
                    removedCagePart={removedCagePart}
                    setRemovedCagePart={setRemovedCagePart}
                    definedParts={definedParts}
                    setDefinedParts={setDefinedParts}
                  />
                ) : (
                  <></>
                )}
              </th>
            </tr>
            {cages.map(cage => {
              return (
                <tr key={cage.id}>
                  <td>
                    {cage.cageType} {cage.cagePart} Panel{" "}
                  </td>
                  <td>{cage.price}</td>
                  <td>
                    {cage.altScreenOptions.map(screen => {
                      return <div key={screen.id}>{screen.altScreenName}</div>;
                    })}
                  </td>
                  <td>
                    {cage.altScreenOptions.map(screen => {
                      return <div key={screen.id}>{screen.price}</div>;
                    })}
                  </td>
                  <td>
                    <button
                      onClick={event => {
                        editCagePart(event, cage);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={event => {
                        deleteCagePartConfirmBox(event, cage);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CagesDashboard;
