/** @format */

import "../App.css";
import { Link } from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <h3>PPSR Admin Page</h3>
      <br />
      <div className='login-link-contianer'>
        <Link to='/login'>Login</Link>
      </div>
    </div>
  );
}

export default App;
