/** @format */

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const AddAltScreen = ({
  altScreenCount,
  setAltScreenCount,
  showAddAltScreen,
  setShowAddAltScreen,
}) => {
  const [altScreen, setAltScreen] = useState({ screen: "" });
  const [altPrice, setAltPrice] = useState({ price: "" });
  let { id } = useParams();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setAltPrice({ price: "" });
      setAltScreen({ screen: "" });
    }
    return () => {
      isMounted = false;
    };
  }, [showAddAltScreen]);

  const submitNewAltScreen = event => {
    event.preventDefault();
    if (altScreen.screen.length > 0 && altPrice.price.length > 0) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/cages/${id}/screen/post`,
        headers: {
          authorization: JSON.parse(localStorage.getItem("ppsr_token")),
        },
        data: {
          altScreenName: altScreen.screen,
          price: altPrice.price,
        },
        response: "json",
      })
        .then(result => {
          //   console.log(result);
          setAltScreenCount(altScreenCount + result.data);
          setShowAddAltScreen(!showAddAltScreen);
        })
        .catch(err => {
          console.log("post error", err);
        });
    } else {
      alert("Something went wrong!\nCheck inputs.");
    }
  };

  return (
    <div>
      <div>
        <select
          name='Alt Screen Type'
          required
          onChange={event => setAltScreen({ screen: event.target.value })}
        >
          <option value=''>Choose Screen Type</option>
          <option value='TuffScreen'>TuffScreen</option>
          <option value='Florida Glass'>Florida Glass</option>
          <option value='NoSeeUms Screen'>NoSeeUms Screen</option>
          <option value='Pet Screen'>Pet Screen</option>
          <option value='Solar Screen'>Solar Screen</option>
        </select>

        <input
          type='number'
          name='alt screen price'
          placeholder='alt screen price'
          value={altPrice.price}
          autoComplete='off'
          required
          onChange={event => setAltPrice({ price: event.target.value })}
        />
      </div>
      <button onClick={event => submitNewAltScreen(event)}>Submit</button>
    </div>
  );
};

export default AddAltScreen;
